// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-account-tsx": () => import("./../../../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-conversions-tsx": () => import("./../../../src/pages/conversions.tsx" /* webpackChunkName: "component---src-pages-conversions-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-feed-your-life-tsx": () => import("./../../../src/pages/feed-your-life.tsx" /* webpackChunkName: "component---src-pages-feed-your-life-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-learn-tsx": () => import("./../../../src/pages/learn.tsx" /* webpackChunkName: "component---src-pages-learn-tsx" */),
  "component---src-pages-my-saved-tsx": () => import("./../../../src/pages/my-saved.tsx" /* webpackChunkName: "component---src-pages-my-saved-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-recipes-tsx": () => import("./../../../src/pages/recipes.tsx" /* webpackChunkName: "component---src-pages-recipes-tsx" */),
  "component---src-pages-search-results-tsx": () => import("./../../../src/pages/search-results.tsx" /* webpackChunkName: "component---src-pages-search-results-tsx" */),
  "component---src-pages-shop-tsx": () => import("./../../../src/pages/shop.tsx" /* webpackChunkName: "component---src-pages-shop-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-templates-feed-your-life-inner-tsx": () => import("./../../../src/templates/feedYourLifeInner.tsx" /* webpackChunkName: "component---src-templates-feed-your-life-inner-tsx" */),
  "component---src-templates-learn-category-template-tsx": () => import("./../../../src/templates/learnCategoryTemplate.tsx" /* webpackChunkName: "component---src-templates-learn-category-template-tsx" */),
  "component---src-templates-learn-inner-template-tsx": () => import("./../../../src/templates/learnInnerTemplate.tsx" /* webpackChunkName: "component---src-templates-learn-inner-template-tsx" */),
  "component---src-templates-product-inner-template-tsx": () => import("./../../../src/templates/productInnerTemplate.tsx" /* webpackChunkName: "component---src-templates-product-inner-template-tsx" */),
  "component---src-templates-recipe-inner-template-tsx": () => import("./../../../src/templates/recipeInnerTemplate.tsx" /* webpackChunkName: "component---src-templates-recipe-inner-template-tsx" */)
}

